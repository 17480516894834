
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';

import {
  getTokenRecord,
  triggerExportUserCsv
} from '@/services/api';

export default {
  emits: ['pageChange', 'sortChange'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const recordList = ref([]);

    const pagination = ref({
      total: 0,
      perPage: 0,
      currentPage: 0,
      lastPage: 0
    });

    const defaultStartTime = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
    const defaultEndTime = dayjs().format('YYYY-MM-DD');
    const dateRange = ref([defaultStartTime, defaultEndTime]);
    const currentPage = ref(1);

    const sortOrder = ref({
      order: 'desc',
      prop: ''
    });

    const fetchTokenRecordData = async() => {
      const response = await getTokenRecord({
        query: {
          ...(dateRange.value && {
            date: `${dateRange.value[0]} ${dateRange.value[1]}`
          }),
          page: currentPage.value,
          sort: sortOrder.value.order,
          orderBy: sortOrder.value.prop
        }
      });
      console.log('vicky - API Response:', response);

      if (response.data && Array.isArray(response.data.recordList)) {
        recordList.value = response.data.recordList;
      } else {
        console.error('vicky-Record list is not an array or is undefined');
      }

      if (response.data && response.data.pagination) {
        pagination.value = {
          total: response.data.pagination.total,
          perPage: response.data.pagination.perPage,
          currentPage: response.data.pagination.currentPage,
          lastPage: response.data.pagination.lastPage
        };
      }
    };

    const exportUserCsv = async() => {
      console.log('export token record csv');

      if (!dateRange.value || dateRange.value.length !== 2) {
        console.log('Date range not selected.');
        return;
      }

      const query = {
        startDate: dateRange.value[0],
        endDate: dateRange.value[1]
      };

      const exportSuccessful = await triggerExportUserCsv({ query });

      if (exportSuccessful) {
        console.log('export csv success', exportSuccessful);
      } else {
        console.log('export csv fail', exportSuccessful);
      }
    };

    const setDateRange = () => {
      fetchTokenRecordData();
    };

    const handlePageChange = (newPage: number) => {
      emit('pageChange', newPage);
    };

    watch([currentPage], () => {
      fetchTokenRecordData();
    });

    onMounted(() => {
      fetchTokenRecordData();
    });

    return {
      t,
      fetchTokenRecordData,
      setDateRange,
      handlePageChange,
      exportUserCsv,
      currentPage,
      dateRange,
      recordList,
      pagination
    };
  }
};
